<template>
  <div>
    <k-crud-page>
      <template #condition>
        <div class="d-flex flex-wrap my-4">
          <!-- <k-text-input
            v-model="cruddataMX.conditions.organizationName"
            class="my-1"
            label="机构名称"
          /> -->
          <k-group-select
            v-model="cruddataMX.conditions.organizationName"
            :items="orgTreeList"
            label="机构名称"
          />
          <k-select
            v-model="cruddataMX.conditions.operationType"
            :items="oprList"
            class="my-1"
            label="检测类型"
          />
          <k-select
            v-model="cruddataMX.conditions.approvalStatus"
            :items="approvalList"
            class="my-1"
            label="审核状态"
          />
          <k-date-range-select label="违规时间" />
          <div class="d-flex justify-end ml-auto">
            <k-primary-button
              class="my-1 mr-4"
              text="检索"
              icon="mdi-magnify"
              @click="crudConditionsMX().resetPage()"
            />
            <k-cancel-button
              class="my-1 mr-4"
              text="重置"
              icon="mdi-restore"
              @click="crudConditionsMX().reset()"
            />
          </div>
        </div>
        <v-divider />
      </template>
      <template #resultlist>
        <k-crud-table
          :headers="columns"
          :items="cruddataMX.dataList"
          :value="cruddataMX.pagination.current"
          :total="cruddataMX.pagination.total"
          @pageChanged="
            (page) => {
              crudPaginationMX().change(page);
            }
          "
        >
          <template #top>
            <v-toolbar flat>
              <k-crud-dialog
                v-model="crud.switch"
                :title="crud.title"
                maxwidth="700px"
                :actionshow="actionShow"
                text="撤销"
                @save="
                  () => {
                    updateStatus(cruddataMX.data, 0);
                  }
                "
                @close="
                  () => {
                    crudActionMX().closeEditingPage(crud);
                  }
                "
                @revoke="
                  () => {
                    updateStatus(cruddataMX.data, 1);
                  }
                "
              >
                <template #dialogContent>
                  <v-form :ref="crud.form.ref">
                    <div class="d-flex flex-wrap my-4">
                      <div>
                        <div>时间：{{ cruddataMX.data.violationTime }}</div>
                        <div>机构：{{ cruddataMX.data.organizationName }}</div>
                        <div>柜台：{{ cruddataMX.data.location }}</div>
                        <div>操作人:{{ cruddataMX.data.oprPeople }}</div>
                        <div>结果：{{ cruddataMX.data.result }}</div>
                        <div v-if="cruddataMX.data.result !== '合规'">
                          结果描述：{{ cruddataMX.data.violationContent }}
                        </div>
                        <div>审核结果：{{ cruddataMX.data.statusName }}</div>
                      </div>
                      <div class="ml-4">
                        <v-img
                          src="https://picsum.photos/350/165?random"
                          width="320"
                          height="200"
                          class="grey darken-4"
                        ></v-img>
                      </div>
                    </div>
                  </v-form>
                </template>
              </k-crud-dialog>
            </v-toolbar>
          </template>
          <template #actions="{ item }">
            <v-btn
              v-if="item.status === '01'"
              text
              color="green"
              @click="crudActionMX().editItem(item, onOpenEdit('01'))"
            >
              处理
            </v-btn>
            <v-btn
              v-else
              text
              color="green"
              @click="crudActionMX().details(crud, item.uuid, onOpenEdit('02'))"
            >
              查看
            </v-btn>
          </template>
        </k-crud-table>
      </template>
    </k-crud-page>
  </div>
</template>
<script>
import KPrimaryButton from '@/components/koi/button/KPrimaryButton.vue';
import KCrudTable from '@/components/koi/KCrudTable.vue';

export default {
  components: { KPrimaryButton, KCrudTable },
  data() {
    return {
      crud: {
        name: '违规预警记录',
        title: '', // 在Mixin中被修改
        switch: false,
        uploading: false,
        loading: false,
        form: {
          ref: 'accountModalForm',
        },
      },
      columns: [
        { text: '序号', value: 'indexNum', sortable: false, align: 'center' },
        {
          text: '机构名称',
          value: 'organizationName',
          sortable: false,
          align: 'center',
        },
        {
          text: '违规时间',
          value: 'violationTime',
          sortable: false,
          align: 'center',
        },
        {
          text: '违规地点',
          value: 'location',
          sortable: false,
          align: 'center',
        },
        {
          text: '检测类型',
          value: 'analysisItemsType',
          sortable: false,
          align: 'center',
        },
        {
          text: '违规内容',
          value: 'violationContent',
          sortable: false,
          align: 'center',
        },
        {
          text: '审核状态',
          value: 'statusName',
          sortable: false,
          align: 'center',
        },
        {
          text: '审核人',
          value: 'approvalPeople',
          sortable: false,
          align: 'center',
        },
        { text: '操作', value: 'actions', sortable: false, align: 'center' },
      ],
      rules: {
        required: (value) => !!value || '不能为空',
      },
      actionShow: true,
      oprList: [],
      approvalList: [],
      // 测试机构
      orgTreeList: [],
    };
  },
  computed: {},
  created() {
    this.getSelectInit();
    this.crudInitMX(this.crud, {
      list: this.$api.violation.violationWarning.getViolationList,
      details: this.$api.violation.violationWarning.getDetail,
    });
  },
  methods: {
    // 编辑&查看
    onOpenEdit(flg) {
      if (flg === '01') {
        this.actionShow = true;
      } else {
        this.actionShow = false;
      }
    },
    // 初期下拉框值获取
    getSelectInit() {
      this.$api.violation.violationWarning.getSelectInit().then((res) => {
        this.oprList = res.data[0].oprList;
        this.orgTreeList = res.data[0].orgTree;
        this.approvalList = res.data[0].approvalStatus;
      });
    },
    // 处理
    updateStatus(item, flg) {
      item.oprFlg = flg;
      this.$api.violation.violationWarning
        .updateViolationStatus(item)
        .then((res) => {
          if (res.code == 200) {
            this.crud.switch = false;
            this.crudConditionsMX().resetPage();
          }
        });
    },
  },
};
</script>
